import store from '@/state/store'
export default [
  {
    path: "/",
    name: "default",
    component: () => import("./views/dashboards/default"),
    meta: {
      authRequired: true,
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/auth/logout"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut')
      next()
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/auth/login"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/auth/register"),
    beforeEnter(routeTo, routeFrom, next) {
      store.getters['auth/loggedIn'] ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/auth/forgot-password"),
    beforeEnter(routeTo, routeFrom, next) {
      store.getters['auth/loggedIn'] ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/recoverpwd",
    name: "Recover pwd",
    component: () => import("./views/auth/recoverpwd"),
    beforeEnter(routeTo, routeFrom, next) {
      store.getters['auth/loggedIn'] ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/confirm-mail",
    name: "confirm-mail",
    meta: { authRequired: true },
    component: () => import("./views/auth/confirm-mail"),
    beforeEnter(routeTo, routeFrom, next) {
      const user = store.getters['auth/loggedIn']
      return user.email_verified ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/email-verification",
    name: "email-verification",
    meta: { authRequired: true },
    component: () => import("./views/auth/email-verification"),
    beforeEnter(routeTo, routeFrom, next) {
      const user = store.getters['auth/loggedIn']
      return user.email_verified ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/auth/forgot-password"),
    beforeEnter(routeTo, routeFrom, next) {
      store.getters['auth/loggedIn'] ? next(routeFrom.path) : next()
    },
  },
  {
    path: "/user/profile",
    name: "User profile",
    meta: { authRequired: true },
    component: () => import("./views/user/profile/Index"),
  },
  {
    path: "/user/profile/edit",
    name: "User profile edit",
    meta: { authRequired: true },
    component: () => import("./views/user/profile/Edit")
  },
  {
    path: "/catalog",
    name: "Course catalog list",
    component: () => import("./views/catalog/CourseListing")
  },
  {
    path: "/catalog/:id",
    name: "Catalog single course",
    component: () => import("./views/catalog/SingleCourse")
  },
  {
    path: "/remote/:id",
    name: "Remote session view",
    component: () => import("./views/remote/SingleRemote")
  },
  {
    path: "/user/course",
    name: "User course list",
    meta: { authRequired: true },
    component: () => import("./views/user/course/Index"),
  },
  {
    path: "/user/course/:id",
    name: "User single course",
    meta: { authRequired: true },
    component: () => import("./views/user/course/Single"),
  },
  {
    path: "/user/article/:id",
    name: "User single article",
    meta: { authRequired: true },
    component: () => import("./views/user/course/article/Single")
  },
  {
    path: "/ticket/create",
    name: "Create ticket",
    meta: { authRequired: true },
    component: () => import("./views/ticket/create")
  },
  {
    path: "/ticket/list",
    name: "Ticket list",
    meta: { authRequired: true },
    component: () => import("./views/ticket/index")
  },
  {
    path: "/ticket/:uuid",
    name: "Ticket view",
    meta: { authRequired: true },
    component: () => import("./views/ticket/show")
  },
  {
    path: "/user/course-learning",
    name: "Internal learning",
    meta: { authRequired: true },
    component: () => import("./views/user/learning/Index")
  },
  {
    path: "/user/course-learning/:id",
    name: "Internal learning single",
    meta: { authRequired: true },
    component: () => import("./views/user/learning/Single")
  },
  {
    path: "/user/article-learning/:id",
    name: "Internal learning single article",
    meta: { authRequired: true },
    component: () => import("./views/user/learning/article/Single")
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
];