<template>
  <router-view></router-view>
</template>
 
 <script>
 
 export default {
   name: 'App',
   components: {
   },
   data: () => ({ count: 1 }),
 }
 </script> 