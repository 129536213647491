import axios from "axios";
import router from "@/router";

export const state = {
  progress: 0,
  completed: 0,
};

export const getters = {
  progress: state => state.progress,
  completed: state => state.completed,
};

export const mutations = {
  setProgress(state, newValue) {
    state.progress = newValue
  },
  setCompleted(state, newValue) {
    state.completed = newValue
  },
};

export const actions = {
  saveProgress({getters}, {url, token, course_type}){
    axios.post(url, {
      article_progress: getters.progress,
      completed: getters.completed,
      course_type: course_type
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(resp => {
      if(resp){
        router.push(router.options.history.state.back)
      }
    })
    .catch(err => err);
  },
  setProgress({ commit }, data){
    commit('setProgress', data.progress);
    commit('setCompleted', data.completed);
  },
};
