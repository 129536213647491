export const state = {
  courseId: 0,
};

export const getters = {
  courseId: state => state.courseId,
};

export const mutations = {
  setCourseId(state, newValue) {
    state.courseId = newValue
  },
};

export const actions = {
  setCourseId({ commit }, id){
    commit('setCourseId', id);
  },
};
