import axios from "axios";
const { VUE_APP_BACKEND_API_URL } = process.env

export const state = {
    currentUser: localStorage.getItem('auth.currentUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    loggedIn(state) {
        let currentUser = state.currentUser
        if(typeof currentUser === 'string'){
            currentUser = JSON.parse(currentUser)
        }

        return currentUser
    },
    userData(state){
        let currentUser = state.currentUser
        let userData;
        if(typeof currentUser === 'string'){
            userData = JSON.parse(currentUser).userData
        }else{
            userData = currentUser.userData
        }

        return userData
    },
}

export const actions = {
    init({state, dispatch}) {
    },

    async logIn({commit, dispatch}, {email, password} = {}) {
        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/user/login`, {
                email: email,
                password: password,
            })

            if (resp.status === 200 && resp.data.access_token) {
                let token_expire = resp.data.expires_in
                resp.data.token_expire = new Date(Date.now() + token_expire * 1000).getTime()
                resp.data.userData = await dispatch('getUserData', resp.data.access_token).then(response => response);

                let user = resp.data

                commit('SET_CURRENT_USER', user)

                return user
            }
        } catch (err) {
            throw err.response
        }
    },

    logOut({commit, getters}) {
        const {access_token} = getters.loggedIn

        axios
            .post(`${VUE_APP_BACKEND_API_URL}/user/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })

        commit('SET_CURRENT_USER', null)
        return true
    },

    async register({commit, dispatch, getters}, {username, email, password, password_confirmation} = {}) {
        try {
            let resp = await axios
                .post(`${VUE_APP_BACKEND_API_URL}/user/register`, {
                    name: username,
                    email: email,
                    password: password,
                    password_confirmation: password_confirmation
                });

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async forgotPassword({commit, dispatch, getters}, {email} = {}) {
        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/forgot-password`, {
                email: email,
            });

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async resetPassword({commit, dispatch, getters}, {token, email, password, password_confirmation} = {}) {
        try {
            let resp = await axios
                .post(`${VUE_APP_BACKEND_API_URL}/reset-password/`, {
                    token: token,
                    email: email,
                    password: password,
                    password_confirmation: password_confirmation,
                });
            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async refreshToken({commit, getters, dispatch}) {
        const {refresh_token} = getters.loggedIn

        try{
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/user/refresh-token/`, {
                refresh_token: refresh_token
            });

            let user = resp.data,
                token_expire = user.expires_in;

            user.token_expire = new Date(Date.now() + token_expire * 1000).getTime()
            user.userData = await dispatch('getUserData', user.access_token).then(response => response);

            if (user) {
                commit('SET_CURRENT_USER', user)
                return user;
            }
        } catch (err) {
            commit('SET_CURRENT_USER', null)
            return false;
        }
    },

    async resendEmail({getters}) {
        const {access_token} = getters.loggedIn

        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/email/verification-notification`, {}, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async verifyEmail({getters}, {verifyParams} = {}) {
        const {access_token} = getters.loggedIn

        try {
            let resp = await axios
                .get(`${VUE_APP_BACKEND_API_URL}/email/verify/${verifyParams}`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async getUserData({getters}, token) {
        const access_token = token ? token : getters.loggedIn.access_token;

        try {
            let resp = await axios
                .get(`${VUE_APP_BACKEND_API_URL}/user`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async editUserData({getters}, {userData, userId} = {}) {
        const {access_token} = getters.loggedIn

        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/user/profile/${userId}/edit`, userData, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async changePassword({getters}, {data, userId} = {}) {
        const {access_token} = getters.loggedIn
        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/user/profile/${userId}/password-change`, data, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async createNewTicket({getters}, {data} = {}) {
        const {access_token} = getters.loggedIn

        console.log("create New Ticket post fired.");

        try {
            let resp = await axios.post(`${VUE_APP_BACKEND_API_URL}/user/tickets/store`, data, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async sendMessage({getters}, {data} ={} ) {
        const {access_token} = getters.loggedIn
        let url = `${VUE_APP_BACKEND_API_URL}/user/tickets/message`;

        try {
            let resp = await axios
                .post(url, data,{
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async closeTicket({getters}, {data} = {}) {
        const {access_token} = getters.loggedIn
        let url = `${VUE_APP_BACKEND_API_URL}/user/tickets/close`;
        try {
            let resp = await axios
                .post(url, data,{
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async getSingleTicket({getters}, {uuid}) {
        const {access_token} = getters.loggedIn
        let url = `${VUE_APP_BACKEND_API_URL}/user/tickets/`+uuid;
        try {
            let resp = await axios
                .get(url, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async getVideos({getters}, {data}) {
        const {access_token} = getters.loggedIn
        let url = `${VUE_APP_BACKEND_API_URL}/wp/user/article/search`;
        console.log('DATA');
        console.log(data)
        try {
            let resp = await axios
                .post(url, data,{
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    },

    async getUserTickets({getters}, {page, status}) {
        console.log('page: '+page+' status:'+status);
        const {access_token} = getters.loggedIn
        let url = `${VUE_APP_BACKEND_API_URL}/user/tickets/index?page=`+page;
        if (status !== null) {
            url = `${VUE_APP_BACKEND_API_URL}/user/tickets/index/`+status+`?page=`+page;
        }
            console.log(url);
        try {
            let resp = await axios
                .get(url, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

            if (resp.status === 200) {
                return resp.data
            }
        } catch (err) {
            throw err.response
        }
    }
}

function saveState(key, state) {
    localStorage.setItem(key, JSON.stringify(state))
}
