import axios from "axios";
const { VUE_APP_BACKEND_API_URL } = process.env
export const actions = {
    async getCategories(){
        try{
            let resp = await axios
                .get(`${VUE_APP_BACKEND_API_URL}/tickets/categories/select`, {

                })

            if(resp.status === 200){
                return resp.data
            }
        }catch(err){
            throw err.response
        }
    },

}
