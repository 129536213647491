import { createWebHistory, createRouter } from "vue-router";

import store from '@/state/store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  const loggedUser = store.getters['auth/loggedIn']
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  if (authRequired && !loggedUser) return next('/login')

  if (loggedUser) {
    const tokenExpiresDate = loggedUser.token_expire;
    const tokenExpires = Date.now() > tokenExpiresDate;

    if(tokenExpires){
      return store.dispatch('auth/refreshToken').then((success) => {
        success ? next() : next('/login')
      })
    }

    return next()
  }

  return next()
})

export default router
